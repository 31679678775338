import React, { useState, useEffect, useRef } from 'react';
import { EventService } from '../../Services/EventService';
import { AppointmentService } from '../../Services/AppointmentService';
import { Redirect, useHistory, useParams } from 'react-router';
import { Col, Row, Card, CardBody, Table, Button, Modal, ModalBody, ModalHeader, ModalFooter, 
        Label, FormGroup, Input, Alert, Form } from 'reactstrap';
import { MyLibrary } from '../../Services/MyLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock, faEdit, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import FileService from '../../Services/FileService';
import { AuthService } from '../../Services/AuthService';
import useForceUpdate from 'use-force-update';
import { NavLink } from 'react-router-dom';

const UserProfile = () => {
    const appointmentService = new AppointmentService();
    const eventService = new EventService();
    const [event, setEvent] = useState({});
    const [myAppointments, setMyAppointments] = useState([]);
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [requesting, setRequesting] = useState('');
    const [message, setMessage] = useState('');
    const [displaying, setDisplaying] = useState('confirmed');
    const [active, setActive] = useState('Profile');
    const [errorPassMessage, setErrorPassMessage] = useState('');
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [user, setUser] = useState({});
    const { userId } = useParams();
    let uploadFile = useRef(null);
    const history = useHistory();
    const fileService = new FileService();
    const authService = new AuthService()
    const forceUpdate = useForceUpdate();
    const me = JSON.parse(localStorage.getItem('digitalEventUser'));

    if(!me){
        return <Redirect to="/networking/login" />
    }

    useEffect(() => {
        eventService.getParticipants(1).then(r => {
            setUser(r.filter(p => p.userId === parseInt(userId))[0]);
            console.log(r.filter(p => p.userId === parseInt(userId))[0])
            forceUpdate();
        }).catch(e => console.log('error fetching'));
        eventService.getEvent(1).then(r => {
            setEvent(r);
        });
    }, []);

    const arrangeAppointment = (userId) => {
        history.push(`/networking/appoint/${userId}`);
    }

    const getUserRole = () => {
        if(event && event.dataToComplete){
            let eventData = JSON.parse(event.dataToComplete);
            if(eventData){
                let userData = JSON.parse(user.data);
                if(userData){
                    for(let i = 0; i < eventData.length; i ++){
                        if(eventData[i] && eventData[i].type === 'Role'){
                            if(userData[eventData[i].name]){
                                return userData[eventData[i].name];
                            }
                        }
                    }
                }
            }
        }
        return '';
    }

    return <> 
        <Row style={{ margin: '10px' }}>
            {user && user.userId > 0 && <>
                <Col md="12" style={{ marginLeft: '10px', marginTop: '25px', marginBottom: '30px' }}>
                    <h4 className="text-primary bold" style={{fontWeight: '900', fontSize: '28px'}}>{ user.name } &gt; {active}</h4>
                </Col>
                <Col md="3">
                    <Card style={{padding: '20px'}}>
                        <div className="text-center">
                            <img src={user.photo ? fileService.getUrl(JSON.parse(user.photo)[0].name) : "/profile2.png"} alt="profile" style={{ width: '50%' }} />
                        </div>
                        <br />
                        <h4>{user.name}</h4>
                        <p>Role in Company: {user.bio}</p>
                        <p>{getUserRole()}</p>
                        <br /> 
                        {
                            user.companyId !== 2 && <>
                                <NavLink to={`/company/${user.companyId}`} style={{ textDecoration: 'none' }}>
                                    <h3 className="text-warning">{user.company.title}</h3>
                                </NavLink>
                                <h6>{user.company.city}, {user.company.country}</h6>
                                <br />
                            </>
                        }
                        
                        <br />
                        <Button color="primary" disabled={active==='Profile'} onClick={() => setActive('Profile')}>Profile</Button><br /><br />
                        {
                            ((user.companyId === 2 && me.companyId !== 2) || (user.companyId !== 2 && me.companyId === 2)) && 
                            <Button onClick={(e) => arrangeAppointment(user.userId)} color="warning" size="sm" style={{ width: "100%" }}>Meet</Button>
                        }
                        
                    </Card>
                </Col>
                <Col md="9">
                    
                    {
                        active === 'Profile' && <Card>
                            <p>{user.bio}</p>
                            <Table striped={false} bordered={false}>
                                {
                                    event.dataToComplete && JSON.parse(event.dataToComplete).map((dt, i) => {
                                        return <tr key={i}>
                                            <th style={{width: '20%'}}>{dt.name}</th>
                                            <td style={{width: '80%'}}>{user.data ? JSON.parse(user.data)[dt.name] : ''}</td>
                                        </tr>
                                    })
                                }
                            </Table>
                            
                        </Card>
                    }
                    
                </Col>
            </>
            }
        </Row>
        
    </>
};

export default UserProfile;

