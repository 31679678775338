import React, { useState, useEffect, Fragment } from 'react';
import { AuthService } from '../../Services/AuthService';
import { EventService } from '../../Services/EventService';
import { Redirect } from 'react-router';
import { Row, Col, Card, Form, FormGroup, Input, Label, Button, Alert, Table, CardBody, NavLink } from 'reactstrap';
import { MyLibrary } from '../../Services/MyLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown, faCommentDots, faEdit, faPlusSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import FileService from '../../Services/FileService';
import DateTimePicker from 'react-datetime-picker';
import useForceUpdate from 'use-force-update';
import { v4 } from 'uuid';

import timezones from 'timezones-list';
import { AppointmentService } from '../../Services/AppointmentService';

const Manage = () => {
    const user = JSON.parse(localStorage.getItem('digitalEventUser'));
    if(!user || user.role !== 'admin'){
        return <Redirect to="/" />
    }
    const [event, setEvent] = useState({});
    const [companies, setCompanies] = useState([]);
    const [eventSessions, setEventSessions] = useState([]);
    const [activeSession, setActiveSession] = useState({eventId: 1});
    const [addingSession, setAddingSession] = useState(false);
    const [search, setSearch] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [activeCompany, setActiveCompany] = useState(0);
    const [addingCompany, setAddingCompany] = useState(false);
    const [newCompany, setNewCompany] = useState({});
    const [stater, setStater] = useState(0);
    const [editingCompany, setEditingCompany] = useState(0);
    const [active, setActive] = useState('Companies');
    const [company, setCompany] = useState({});
    const authService = new AuthService();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [name, setName] = useState('');
    const [creatingAdmin, setCreatingAdmin] = useState(false);
    const [days, setDays] = useState([]);
    const [hours, setHours] = useState([]);
    const [allAppointments, setAllAppointments] = useState([]);
    const dds = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [bio, setBio] = useState('');
    const role = 'admin';
    const roleInCompany = 'Admin';
    const [admins, setAdmins] = useState([]);
    const companyId = 1;
    const eventService = new EventService();
    const fileService = new FileService();
    const forceUpdate = useForceUpdate();
    const appointmentsService = new AppointmentService();

    useEffect(() => {
        if(user && user.role === 'admin'){
            authService.whoami().then(r => console.log(r));
            authService.getCompanies(search).then(r => setCompanies(r)).catch(e => setErrorMessage(e));
            eventService.getEvent(1).then(r => {
                setEvent(r);
                calculateAvailability(r);
            }).catch(e => setErrorMessage(e));
            eventService.getSessions().then(r => setEventSessions(r));
            authService.getAdmins().then(r => setAdmins(r));
            appointmentsService.getAllAppointments().then(r => {
                setAllAppointments(r);
                console.log(r);
            });
        }
    }, []);

    useEffect(() => {
        if(user && user.role === 'admin'){
            authService.getCompanies(search).then(r => setCompanies(r)).catch(e => setErrorMessage(e));
        }
    }, [search]);

    const calculateAvailability = (ev) => {
        let dateStart = new Date(ev.dateStart.split('T')[0]);
        let dateEnd = new Date(ev.dateEnd);
        let ds = [];
        ds.push(dds[dateStart.getDay()] + ' ' + months[dateStart.getMonth()] + ' ' + dateStart.getDate().toString().padStart(2, '0'));
        while(dateStart < dateEnd){
            dateStart.setDate(dateStart.getDate() + 1);
            ds.push(dds[dateStart.getDay()] + ' ' + months[dateStart.getMonth()] + ' ' + dateStart.getDate().toString().padStart(2, '0'));
        }
        setDays(ds);

        let hhs = MyLibrary.getHours(true).filter(h => h >= ev.timeStart && h <= ev.timeEnd);
        setHours(hhs);
        forceUpdate();
    };

    const saveEvent = (e) => {
        if(e){
            e.preventDefault();
        }
        eventService.saveEvent(event).then(r => {
            setSuccessMessage('The event was saved successfully');
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }).catch(e => {
            setErrorMessage(e.message);
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        });
    };

    const saveNewCompany = (e) => {
        e.preventDefault();
        authService.saveCompany(newCompany).then(r => {
            setSuccessMessage('Η εταιρεία δημιουργήθηκε επιτυχώς');
            authService.getCompanies(search).then(r => setCompanies(r)).catch(e => setErrorMessage(e));
            setAddingCompany(false);
            setNewCompany({});
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000)
        }).catch(e => {
            setErrorMessage('Η εταιρεία δεν δημιουργήθηκε');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000)
        })
    };
    const saveCompany = (e) => {
        e.preventDefault();
        authService.saveCompany(company).then(r => {
            setSuccessMessage('The company was saved successfully');
            authService.getCompanies(search).then(r => setCompanies(r)).catch(e => setErrorMessage(e));
            setEditingCompany(false);
            setCompany({});
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000)
        }).catch(e => {
            setErrorMessage('The company was not saved');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000)
        })
    };

    const initializeCompany = (companyId) => {
        authService.initializeCompany(companyId).then(r => {
            setSuccessMessage('ο αρχικός χρήστης της εταιρείας δημιουργήθηκε. Του απεστάλη ένα email με τα στοιχεία πρόσβασής του.')
            
            setTimeout(() => {
                setSuccessMessage('');
                authService.getCompanies(search).then(r => {
                    setCompanies(r);
                    forceUpdate();
                }).catch(e => setErrorMessage(e));
            }, 4000);
        }).catch(e => setErrorMessage(e));
    };

    const uploadEventFile = (file) => {
        const uploadPromise = new Promise((resolve, reject) => {
            fileService.uploadFile(file).then(r => {
                let existingImages;
                existingImages = r;
                setEvent({
                    ...event,
                    logo: existingImages
                });
                resolve(true);
            });
        });
        return uploadPromise;
    };

    const addEventData = () => {
        let arr = [];
        if(event.dataToComplete){
            arr = JSON.parse(event.dataToComplete);
        }
        arr.push({name: ''});
        setEvent({
            ...event,
            dataToComplete: JSON.stringify(arr)
        });
    };

    const setDataToCompleteName = (i, name) => {
        let data = JSON.parse(event.dataToComplete);
        if(data[i]){
            data[i].name = name;
        }
        setEvent({
            ...event,
            dataToComplete: JSON.stringify(data)
        });
    };

    const setDataToCompleteType = (i, type) => {
        let data = JSON.parse(event.dataToComplete);
        if(data[i]){
            data[i].type = type;
        }
        setEvent({
            ...event,
            dataToComplete: JSON.stringify(data)
        });
    };

    const setDataToCompleteOptions = (i, options) => {
        let data = JSON.parse(event.dataToComplete);
        if(data[i]){
            data[i].options = options;
        }
        setEvent({
            ...event,
            dataToComplete: JSON.stringify(data)
        });
    };

    const addCompanyActivity = () => {
        let arr = [];
        if(event.companyActivities){
            arr = JSON.parse(event.companyActivities);
        }
        arr.push({name: '', color: ''});
        setEvent({
            ...event,
            companyActivities: JSON.stringify(arr)
        });
    };

    const setCompanyActivityName = (i, name) => {
        let data = JSON.parse(event.companyActivities);
        if(data[i]){
            data[i].name = name;
        }
        setEvent({
            ...event,
            companyActivities: JSON.stringify(data)
        });
    };

    const setCompanyActivityColor = (i, color) => {
        let data = JSON.parse(event.companyActivities);
        if(data[i]){
            data[i].color = color;
        }
        setEvent({
            ...event,
            companyActivities: JSON.stringify(data)
        });
    };

    const saveEventSession = () => {
        eventService.saveEventSession(activeSession).then(r => {
            eventService.getSessions().then(r => {setEventSessions(r); setActiveSession({}); setAddingSession(false)});
        });
    };

    const deleteEventSession = (esId) => {
        eventService.deleteEventSession(esId).then(r => {
            eventService.getSessions().then(r => setEventSessions(r));
        });
    }
    const submitRegister = (e) => {
        e.preventDefault();
        //console.log('submitted: ' + email + '-' + password);
        authService.register(email, password, name, bio, role, roleInCompany, companyId).then(registerData => {
            fetchAdmins();
            setCreatingAdmin(false);
        }).catch(e => {
            console.log(e);
        });
    };

    const fetchAdmins = () => {
        authService.getAdmins().then(r => setAdmins(r));
    }

    const deleteUser = (userId) => {
        if(window.confirm('Are you sure you want to delete the user?')){
            authService.deleteUser(userId).then(r => {
                authService.getCompanies(search).then(r => setCompanies(r)).catch(e => setErrorMessage(e));
                authService.getAdmins().then(r => setAdmins(r));
            });
        }
    };

    const deleteCompany = (companyId) => {
        if(window.confirm('Are you sure you want to delete the company?')){
            authService.deleteCompany(companyId).then(r => {
                setTimeout(() => {
                    authService.getCompanies(search).then(r => setCompanies(r)).catch(e => setErrorMessage(e));
                    forceUpdate()
                }, 100);
            });
        }
    }

    const deleteUserData = (i) => {
        let ud = JSON.parse(event.dataToComplete);
        ud.splice(i, 1);
        setEvent({
            ...event,
            dataToComplete: JSON.stringify(ud)
        });
    }

    const deleteCompanyActivity = (i) => {
        let activities = JSON.parse(event.companyActivities);
        activities.splice(i, 1);
        setEvent({...event, companyActivities: JSON.stringify(activities)})
    }

    return <>
        <Row style={{padding: '15px'}}>
            <Col md="12" style={{marginBottom: '20px'}}>
                <Card>
                    <h3>Manage Event</h3>
                    <h4>{ MyLibrary.getDateDMY(new Date(Date.now())) }</h4>
                </Card>    
            </Col>
            <Col md="4">
                <Card>
                    <h4>Event</h4>
                    {
                        successMessage && <Alert color="success">{successMessage}</Alert>
                    }
                    {
                        errorMessage && <Alert color="danger">{errorMessage}</Alert>
                    }
                    <Form onSubmit={saveEvent}>
                        <FormGroup>
                            <Label>Title</Label>
                            <Input type="text" defaultValue={event.title} onChange={(e) => setEvent({...event, title: e.target.value})} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <Input type="text" defaultValue={event.description} onChange={(e) => setEvent({...event, description: e.target.value})} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Date Starting</Label>
                            <Input type="date" defaultValue={event.dateStart ? MyLibrary.getDateDMY(event.dateStart) : MyLibrary.getDateDMY(new Date(Date.now()))} 
                                onChange={(e) => setEvent({...event, dateStart: e.target.valueAsDate})} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Time Starting</Label>
                            <Input type="select" value={event.timeStart} onChange={(e) => setEvent({...event, timeStart: e.target.value})}>
                                {
                                    MyLibrary.getHours(true).map(h => <option key={h}>{h}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Date Ending</Label>
                            <Input type="date" defaultValue={event.dateEnd ? MyLibrary.getDateDMY(event.dateEnd) : MyLibrary.getDateDMY(new Date(Date.now()))} 
                                onChange={(e) => setEvent({...event, dateEnd: e.target.valueAsDate})} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Time Ending</Label>
                            <Input type="select" value={event.timeEnd} onChange={(e) => setEvent({...event, timeEnd: e.target.value})}>
                                {
                                    MyLibrary.getHours(true).map(h => <option key={h}>{h}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Stage</Label>
                            <Input type="text" value={event.eventYoutubeLink} onChange={(e) => setEvent({...event, eventYoutubeLink: e.target.value})} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Timezone</Label>
                            <Input type='select' value={event.timezone} 
                                                    onChange={(e) => setEvent({
                                                        ...event,
                                                        timezone: e.target.value
                                                    })} >
                                                    <option value="">Select one of the below</option>
                                                    {
                                                        timezones.map(t => <option value={t.tzCode} key={t.label}>{t.label}</option>)
                                                    }
                                                </Input>
                        </FormGroup>
                        <hr />
                        <FormGroup>
                            <Label>Logo</Label>
                            <Input type="file" onChange={(e) => uploadEventFile(e.target.files[0])} />
                            {
                                event.logo && <img src={fileService.getUrl(JSON.parse(event.logo)[0].name)} style={{width: '100px'}} alt="logo"/>
                            }
                        </FormGroup>
                        <hr />
                        
                        <Button color="primary">
                            Save
                        </Button>
                    </Form>
                </Card>
            </Col>
            <Col md="8">
                <Row>
                    <Col md="2">
                        <Button color="link" disabled={active === 'Company'} onClick={() => setActive('Companies')}>Companies</Button>
                    </Col>
                    <Col md="2">
                        
                        <Button color="link" disabled={active === 'Event Sessions'} onClick={() => setActive('Event Sessions')}>Event Sessions</Button>
                    </Col>
                    <Col md="2">
                        <Button color="link" disabled={active === 'User Data'} onClick={() => setActive('User Data')}>User Data</Button>
                    </Col>
                    <Col md="2">
                        <Button color="link" disabled={active === 'Company Activities'} 
                            onClick={() => setActive('Company Activities')}>Company Activities</Button>
                    </Col>
                    <Col md="2">
                        <Button color="link" disabled={active === 'Admins'} onClick={() => setActive('Admins')}>Admins</Button>
                    </Col>
                    <Col md="2">
                        <Button color="link" disabled={active === 'Stats'} onClick={() => setActive('Stats')}>Stats</Button>
                    </Col>
                </Row>
                {
                    active === 'Companies' && <Card>
                        <h4>Companies</h4>
                        <hr />
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Input type="search" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <Button className="float-end" color="primary" size="sm" onClick={() => setAddingCompany(true)}><FontAwesomeIcon icon={faPlusSquare} /> </Button>
                            </Col>
                        </Row>
                        {
                            addingCompany && <>
                            <hr />
                            <Form onSubmit={saveNewCompany}>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>Booth Manager Name</Label>
                                            <Input type="text" onChange={(e) => {setNewCompany({...newCompany, name: e.target.value})}} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>Company Name</Label>
                                            <Input type="text" onChange={(e) => {setNewCompany({...newCompany, title: e.target.value})}} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input type="textarea" onChange={e => setNewCompany({...newCompany, description: e.target.value})} />
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>City</Label>
                                            <Input type="text" onChange={(e) => {setNewCompany({...newCompany, city: e.target.value})}} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>Country</Label>
                                            <Input type="text" onChange={(e) => {setNewCompany({...newCompany, country: e.target.value})}} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>Website</Label>
                                            <Input type="text" onChange={(e) => {setNewCompany({...newCompany, address: e.target.value})}} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input type="email" onChange={(e) => {setNewCompany({...newCompany, email: e.target.value})}} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>Phone</Label>
                                            <Input type="text" onChange={(e) => {setNewCompany({...newCompany, phone: e.target.value})}} />
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md="12" className="text-end">
                                        <Button color="primary" size="sm">Save</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <hr />
                            </>
                        }
                        {
                            editingCompany && <>
                            <hr />
                            <Form onSubmit={saveCompany}>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>Booth Manager Name</Label>
                                            <Input type="text" onChange={(e) => {setCompany({...company, name: e.target.value})}} defaultValue={company.name} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>Company Name</Label>
                                            <Input type="text" onChange={(e) => {setCompany({...company, title: e.target.value})}} defaultValue={company.title} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input type="textarea" onChange={e => setCompany({...company, description: e.target.value})} defaultValue={company.description}/>
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>City</Label>
                                            <Input type="text" onChange={(e) => {setCompany({...company, city: e.target.value})}} defaultValue={company.city}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>Country</Label>
                                            <Input type="text" onChange={(e) => {setCompany({...company, country: e.target.value})}} defaultValue={company.country} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>Website</Label>
                                            <Input type="text" onChange={(e) => {setCompany({...company, address: e.target.value})}} defaultValue={company.address} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>Phone</Label>
                                            <Input type="text" onChange={(e) => {setCompany({...company, phone: e.target.value})}} defaultValue={company.phone} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input type="email" onChange={(e) => {setCompany({...company, email: e.target.value})}} defaultValue={company.email} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12" className="text-end">
                                        <Button color="primary" size="sm">Save</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <hr />
                            </>
                        }
                        <Table>
                            <thead>
                                <tr>
                                    <th>Booth manager</th>
                                    <th>Company</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Users</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    companies.map(c => {
                                        return <Fragment key={c.companyId}>
                                            <tr key={c.companyId}>
                                                <td>{c.name}</td>
                                                <td>{c.title}</td>
                                                <td>{c.email}</td>
                                                <td>{c.phone}</td>
                                                <td>{c.users.length}</td>
                                                <td className="text-end">
                                                    <Button color="success" size="sm" onClick={ () => {
                                                                    setEditingCompany(true);
                                                                    setCompany({...c});
                                                                } }><FontAwesomeIcon icon={faEdit} /></Button>&nbsp;
                                                    <Button  className="text-right" color="info" 
                                                        onClick={() => setActiveCompany(c.companyId !== activeCompany ? c.companyId : 0)} 
                                                        size="sm">
                                                            <FontAwesomeIcon icon={faArrowAltCircleDown}  /></Button>&nbsp;
                                                    {c.companyId > 2 && 
                                                    <Button onClick={() => deleteCompany(c.companyId)} color="danger" size="sm"><FontAwesomeIcon icon={faTimesCircle}/></Button>}
                                                    {
                                                        c.users.length === 0 && <Button color="warning" size="sm" onClick={() => initializeCompany(c.companyId)}>Initialize</Button>
                                                    }
                                                    <a href={`/managebooth/${c.companyId}`} className="btn btn-sm btn-warning" ><FontAwesomeIcon icon={faCommentDots} /></a>
                                                </td>
                                            </tr>
                                            {
                                                c.companyId === activeCompany && <>
                                                    <tr>
                                                        <th></th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Role</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    {
                                                        c.users && c.users.map(u => <tr key={u.userId}>
                                                            <td></td>
                                                            <td>{u.name}</td>
                                                            <td>{u.email}</td>
                                                            <td>{u.roleInCompany}</td>
                                                            <td><a href={`/networking/edituser/${u.userId}`} className="btn btn-sm btn-primary">
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </a>
                                                                <Button size="sm" color="danger" onClick={() => {
                                                                    deleteUser(u.userId);
                                                                }}>
                                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                                </Button>
                                                            </td>
                                                        </tr>)
                                                    }
                                                </>
                                            }
                                        </Fragment>
                                    })
                                }
                            </tbody>
                        </Table>
                    </Card>
                }
                {
                    active === 'Event Sessions' && <Card>
                        {
                            event.eventId && <>
                                <h3>Event Sessions</h3>
                                <div className="text-end">
                                    <Button size="sm" type="button" color="warning" onClick={() => setAddingSession(true)}>+</Button>
                                </div>
                                {
                                    (addingSession || activeSession.esId) && <>
                                        <FormGroup>
                                            <Label>Title</Label>
                                            <Input type="text" value={activeSession.title} onChange={(e) => setActiveSession({
                                                ...activeSession,
                                                title: e.target.value
                                            })} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input type="textarea" value={activeSession.description} onChange={(e) => setActiveSession({
                                                ...activeSession,
                                                description: e.target.value
                                            })} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Url</Label>
                                            <Input type="text" value={activeSession.url} onChange={(e) => setActiveSession({
                                                ...activeSession,
                                                url: e.target.value
                                            })} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Date and Time</Label>
                                            <DateTimePicker displayTimeZone={event.timezone ? event.timezone : 'Europe/Athens'} className="form-control" value={activeSession.dateTime ? new Date(activeSession.dateTime) : null} onChange={(e) => setActiveSession({
                                                ...activeSession,
                                                dateTime: e
                                            })} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Duration (in minutes)</Label>
                                            <Input type="number" value={activeSession.duration} onChange={(e) => setActiveSession({
                                                ...activeSession,
                                                duration: parseInt(e.target.value)
                                            })} />
                                        </FormGroup>
                                        <Button color="primary" size="sm" type="button" onClick={() => saveEventSession()}>Save</Button>
                                    </>
                                }
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th className="text-end">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        eventSessions && eventSessions.map(es => <tr key={es.esId}>
                                            <td>{es.title}</td>
                                            <td className="text-end">
                                                <Button color="primary" size="sm" type="button" onClick={() => setActiveSession(es)}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>{' '}
                                                <Button color="danger" size="sm" type="button" onClick={() => deleteEventSession(es.esId)}>
                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                </Button>
                                            </td>
                                        </tr>)
                                    }
                                    </tbody>
                                </Table>
                                
                            </>
                        }
                    </Card>
                }
                {
                    active === 'User Data' && <Card>
                        <h5>User Data</h5>
                        <div className="text-end">
                            <Button color="success" type="button" size="sm" onClick={() => addEventData() }>+</Button>
                            {
                                event.dataToComplete && JSON.parse(event.dataToComplete).map((dt, i) => {
                                    return <Row>
                                        <Col md="5">
                                            <FormGroup>
                                                <Input type="text" defaultValue={dt.name} onChange={(e) => setDataToCompleteName(i, e.target.value)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="7">
                                            <Input type="select" defaultValue={dt.type} onChange={(e) => setDataToCompleteType(i, e.target.value)}>
                                                <option value="">Select Type</option>
                                                <option>Text</option>
                                                <option>Dropdown</option>
                                                <option>Date</option>
                                                <option>Timezone</option>
                                                <option>Role</option>
                                            </Input>
                                        </Col>
                                        {
                                            (dt.type === 'Dropdown' || dt.type === 'Role') && <Col md="12">
                                                <FormGroup>
                                                    <Input type="text" onChange={(e) => setDataToCompleteOptions(i, e.target.value)} 
                                                        defaultValue={dt.options}/>
                                                </FormGroup>
                                            </Col>
                                        }
                                        <Col md="12">
                                            <Button color="danger" size="sm" onClick={() => {
                                                deleteUserData(i);
                                            }}> <FontAwesomeIcon icon={faTimesCircle} /> </Button>
                                        </Col>
                                        <hr />
                                    </Row>
                                })
                            }
                        </div>
                        <Button color="primary" onClick={() => saveEvent()}>Save</Button>
                    </Card>
                }
                {
                    active === 'Company Activities' && <Card>
                        <h5>Company Activities</h5>
                        <div className="text-end">
                            <Button color="success" type="button" size="sm" onClick={() => addCompanyActivity() }>+</Button>
                            {
                                event.companyActivities && JSON.parse(event.companyActivities).map((dt, i) => {
                                    return <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <Input type="text" defaultValue={dt.name} onChange={(e) => setCompanyActivityName(i, e.target.value)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Input type="select" value={dt.color} onChange={(e) => setCompanyActivityColor(i, e.target.value)}>
                                                    <option value="">Choose Color</option>
                                                    <option value="#f00">Red</option>
                                                    <option value="#0f0">Green</option>
                                                    <option value="#00f">Blue</option>
                                                    <option value="#C8E7FD">Cyan</option>
                                                    <option value="#FE9F35">Orange</option>
                                                    <option value="#8F00FF">Violet</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <Button color="danger" size="sm" onClick={() => deleteCompanyActivity(i)}><FontAwesomeIcon icon={faTimesCircle} /></Button>
                                        </Col>
                                        <hr />
                                    </Row>
                                })
                            }
                        </div>
                        <Button color="primary" onClick={() => saveEvent()}>Save</Button>
                    </Card>
                }
                {
                    active === 'Admins' && <Card>
                        <CardBody>
                            {
                                creatingAdmin && <Form onSubmit={submitRegister}>
                                    <FormGroup>
                                        <Input type='email' className="mycustomInput" placeholder="Email"
                                            onChange={(e) => { setEmail(e.target.value) }} />
                                    </FormGroup>
                                    <br />
                                    <FormGroup>
                                        <Input type='password' placeholder="Password" className="mycustomInput" 
                                            onChange={(e) => { setPassword(e.target.value) }} />
                                    </FormGroup>
                                    <br />
                                    <FormGroup>
                                        <Input type='password' placeholder="Repeat Password" className="mycustomInput" 
                                            onChange={(e) => { setRepeatPassword(e.target.value) }} />
                                    </FormGroup>
                                    <br />
                                    <FormGroup>
                                        <Input type='text' placeholder="Name" className="mycustomInput" 
                                            onChange={(e) => { setName(e.target.value) }} />
                                    </FormGroup>
                                    <br />
                                    <FormGroup>
                                        <Input type='text' placeholder="Company" className="mycustomInput" 
                                            onChange={(e) => { setCompany(e.target.value) }} />
                                    </FormGroup>
                                    <br />
                                    <FormGroup>
                                        <Input type='textarea' placeholder="Bio" className="mycustomInput" 
                                            onChange={(e) => { setBio(e.target.value) }} />
                                    </FormGroup>
                                    <br />
                                    <div className="d-grid gap-2">
                                        <Button color="primary" className="myCustomButton" size="sm" block>Register</Button>
                                    </div>
                                    
                                </Form>
                            }
                            <div className="text-end">
                                <Button color="primary" size="sm" onClick={() => setCreatingAdmin(true)}>+</Button>
                            </div>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th className='text-end'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        admins.map(a => {
                                            return <tr key={a.userid}>
                                                <td>{a.name}</td>
                                                <td>{a.email}</td>
                                                <td className="text-end">
                                                    <Button color="danger" size="sm" onClick={() => {
                                                        deleteUser(a.userId);
                                                    }}><FontAwesomeIcon icon={faTimesCircle} /></Button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                }
                {
                    active === 'Stats' && <Card>
                        <CardBody>
                            <Table borderless>
                                <thead>
                                    <tr>
                                        <th className="text-primary"></th>
                                        {
                                            days.map(d => <th key={d} className="text-primary">{d}</th>)
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        hours.map((h, i) => <tr key={v4()}>
                                            <td className="align-middle text-primary">{h}</td>
                                            {
                                                days.map(d => {
                                                    return <td>
                                                        {
                                                            allAppointments.filter(a => a.datetime === d + ' ' + h).map(a => {
                                                                return <p><a className={a.status === 'pending' ? 'text-info' : (a.status === 'confirmed' ? 'text-success' : (a.status === 'completed' ? 'text-primary' : 'text-danger'))} href={`/networking/profile/${a.userRequested.userId}`} target="_blank">{a.userRequested.name}</a> - {' '}
                                                                <a href={`/networking/profile/${a.userRequested.userId}`} className={a.status === 'pending' ? 'text-info' : (a.status === 'confirmed' ? 'text-success' : (a.status === 'completed' ? 'text-primary' : 'text-danger'))} target="_blank">{a.userReceiver.name}</a> ({a.status === 'pending' ? 'Pending' : (a.status === 'confirmed' ? 'Confirmed' : (a.status === 'completed' ? 'Completed' : 'Canceled'))})</p>
                                                            })
                                                        }
                                                    </td>
                                                })
                                            }
                                        </tr>)
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                }
            </Col>
        </Row>
    </>


}

export default Manage;