import React, { useState, useEffect, useRef } from 'react';
import { EventService } from '../../Services/EventService';
import { AppointmentService } from '../../Services/AppointmentService';
import { Redirect, useParams } from 'react-router';
import { Col, Row, Card, CardBody, Table, Button, Modal, ModalBody, ModalHeader, ModalFooter, 
        Label, FormGroup, Input, Alert, Form } from 'reactstrap';
import { MyLibrary } from '../../Services/MyLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock, faEdit, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import FileService from '../../Services/FileService';
import timezones from 'timezones-list';
import { AuthService } from '../../Services/AuthService';
import useForceUpdate from 'use-force-update';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

const Profile = () => {
    const appointmentService = new AppointmentService();
    const eventService = new EventService();
    const [appointments, setAppointments] = useState([]);
    const [event, setEvent] = useState({});
    const [myAppointments, setMyAppointments] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [participant, setParticipant] = useState({}); 
    const [days, setDays] = useState([]);
    const [hours, setHours] = useState([]);
    const [unavailableHours, setUnavailableHours] = useState([]);
    const [unavailableHours2, setUnavailableHours2] = useState([]);
    const [appointmentUrl, setAppointmentUrl] = useState('')
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [setter, setSetter] = useState(0);
    const [showMeetingModal, setShowMeetingModal] = useState(false);
    const [requesting, setRequesting] = useState('');
    const [timezone, setTimezone] = useState('Europe/Athens');
    const [myTimezone, setMyTimezone] = useState('');
    const [showMyTimezone, setShowMyTimezone] = useState(false);
    const [message, setMessage] = useState('');
    const [displaying, setDisplaying] = useState('confirmed');
    const [active, setActive] = useState('Meetings');
    const [errorPassMessage, setErrorPassMessage] = useState('');
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [needToCompleteData, setNeedToCompleteData] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('digitalEventUser')));
    const dds = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let uploadFile = useRef(null);
    const history = useHistory();
    const fileService = new FileService();
    const authService = new AuthService()
    const forceUpdate = useForceUpdate();

    if(!user){
        return <Redirect to="/networking/login" />
    }

    const toggleChangePasswordModal = () => {
        setShowChangePasswordModal(!showChangePasswordModal);
    }

    const toggleMeetingModal = () => {
        setShowMeetingModal(!showMeetingModal);
    }

    useEffect(() => {
        eventService.getParticipants(1).then(r => {
            setParticipants(r);
            setParticipant(r.filter(p => p.userId === parseInt(otherUserId))[0]);
        }).catch(e => console.log('error fetching'));
        eventService.getEvent(1).then(r => {
            if(r){
                console.log(r)
                calculateAvailability(r);
                setEvent(r);
                if(r.timezone){
                    setTimezone(r.timezone);
                }
                let userData = JSON.parse(user.data);
                let eventData = JSON.parse(r.dataToComplete);
                if(userData){
                    for(let i = 0; i < eventData.length; i ++){
                        let td = eventData[i];
                        if(!userData || !userData[td.name] ){
                            setNeedToCompleteData(true);
                        }
                    }
                }
                else{
                    setNeedToCompleteData(true)
                }
                checkTimezone(r);
            }
        });
        appointmentService.getAppointments(user.userId).then(r => {
            setMyAppointments(r);
            calculateMyBusy(r);
        });
    }, []);

    const getUserRole = () => {
        if(event && event.dataToComplete){
            let eventData = JSON.parse(event.dataToComplete);
            if(eventData){
                let userData = JSON.parse(user.data);
                if(userData){
                    for(let i = 0; i < eventData.length; i ++){
                        if(eventData[i].type === 'Role'){
                            return userData[eventData[i].name];
                        }
                    }
                }
            }
        }
        return '';
    }

    const calculateAvailability = (ev) => {
        let dateStart = new Date(ev.dateStart.split('T')[0]);
        let dateEnd = new Date(ev.dateEnd);
        let ds = [];
        ds.push(dds[dateStart.getDay()] + ' ' + months[dateStart.getMonth()] + ' ' + dateStart.getDate().toString().padStart(2, '0'));
        while(dateStart < dateEnd){
            dateStart.setDate(dateStart.getDate() + 1);
            ds.push(dds[dateStart.getDay()] + ' ' + months[dateStart.getMonth()] + ' ' + dateStart.getDate().toString().padStart(2, '0'));
        }
        setDays(ds);

        let hhs = MyLibrary.getHours(true).filter(h => h >= event.timeStart && h <= event.timeEnd);
        console.log(hhs)
        setHours(hhs);
        forceUpdate();
    };

    const checkTimezone = (event) => {
        let eventData = JSON.parse(event.dataToComplete);
        let userData = JSON.parse(user.data);
        if(eventData.filter(dt => dt.type === 'Timezone').length > 0){
            let dt = eventData.filter(dt => dt.type === 'Timezone')[0];
            if(userData && userData[dt.name]){
                setMyTimezone(userData[dt.name]);
            }
        }
    };

    const calculateMyBusy = (aps) => {
        let busys = [];
        for(let i = 0; i < aps.length; i ++){
            busys.push(aps[i].datetime);
        }
        setUnavailableHours2(busys);
    };

    const selectFile = () => {
        document.getElementById('profilePicture').click();
    }

    const uploadProfilePicture = (file) => {
        const uploadPromise = new Promise((resolve, reject) => {
            fileService.uploadFile(file).then(r => {
                
                let existingImages;
            
                existingImages = r;
                setUser({
                    ...user,
                    photo: existingImages
                });
                
                authService.updateUser({
                    ...user,
                    photo: existingImages
                });
                localStorage.setItem('digitalEventUser', JSON.stringify({...user, photo: existingImages}));
                resolve(true);
            });
        });
        return uploadPromise;
    };

    const confirmMeeting = (appointmentId) => {
        appointmentService.acceptAppointment(appointmentId).then(r => {
            appointmentService.getAppointments(user.userId).then(r => {
                setMyAppointments(r);
            });
        })
    }

    const cancelMeeting = (appointmentId) => {
        if(window.confirm('Are you sure you want to cancel this meeting?')){
            appointmentService.cancelAppointment(appointmentId).then(r => {
                appointmentService.getAppointments(user.userId).then(r => {
                    setMyAppointments(r);
                });
            });
        }
    }

    const completeMeeting = (appointmentId) => {
        if(window.confirm('Are you sure you want to mark this meeting as Completed?')){
            appointmentService.completeAppointment(appointmentId).then(r => {
                appointmentService.getAppointments(user.userId).then(r => {
                    setMyAppointments(r);
                });
            });
        }
    }

    const rescheduleMeeting = (appointmentId) => {
        if(window.confirm('Are you sure you want to reschedule the meeting? This meeting will be canceled and you will need to request a new meeting from the other user')){
            appointmentService.cancelAppointment(appointmentId).then(r => {
                appointmentService.getAppointments(user.userId).then(r => {
                    setMyAppointments(r);
                    history.push(`/networking/appoint/${userId}`);
                });
            });
        }
    };
    
    const updateProfile = (e) => {
        e.preventDefault();
        authService.updateUser(user).then(r => {
            setSuccessMessage('Your profile was changed successfully');
            setTimeout(() => {
                setSuccessMessage('');
                let userData = JSON.parse(user.data);
                let eventData = JSON.parse(event.dataToComplete);
                console.log(userData);
                console.log(eventData);
                let flag = false;
                if(userData && eventData){
                    for(let k = 0; k < eventData.length; k ++){
                        if(userData[eventData[k].name] <= ''){
                            flag = true;
                        }
                    }
                }
                console.log(flag);
                setNeedToCompleteData(flag);
            }, 3000);
        });
    }

    const updateUserData = (i, d) => {
        console.log(user.data);
        const data = user.data ? JSON.parse(user.data) : {};
        data[i] = d;
        console.log(i, d)
        setUser({
            ...user, 
            data: JSON.stringify(data)
        });
        localStorage.setItem('digitalEventUser', JSON.stringify({...user, data: JSON.stringify(data)}));
    };

    const changePassword = (e) => {
        e.preventDefault();
        if(newPassword !== repeatPassword){
            setErrorPassMessage('Tha passwords do not match');
            setTimeout(() => {
                setErrorPassMessage('');
            }, 3000);
            return false;
        }
        else{
            authService.changePassword(user.userId, newPassword).then(r => toggleChangePasswordModal());
        }
    };

    const calcLocalTime = (time) => {
        const initTimezone = timezones.filter(t => t.tzCode === timezone)[0];
        const finalTimezone = timezones.filter(t => t.tzCode === myTimezone)[0];
        let initMargin = parseInt(initTimezone.utc.split(':')[0]);
        let finMargin = parseInt(finalTimezone.utc.split(':')[0]);
        let margin = initMargin - finMargin;
        let initTime = parseInt(time.split(':')[0]);
        let minutes = time.split(':')[1];
        return ((initTime + margin).toString() !== '24' ? (initTime + margin).toString() : '00') + ':' + minutes;
    }

    const startAppointnemt = (appointmentId) => {
        appointmentService.startAppointment(appointmentId).then(r => console.log(r));
    }

    return <> 
        <Row style={{ margin: '10px' }}>
            {user && user.userId > 0 && <>
                <Col md="12" style={{ marginLeft: '10px', marginTop: '25px', marginBottom: '30px' }}>
                    {   
                        myTimezone && !showMyTimezone && myTimezone !== timezone && <span className="text-primary float-end">
                            Hours are displayed in {timezone} timezone. <Button color="link" onClick={() => setShowMyTimezone(true)}>
                                Show in my timezone ({myTimezone})
                            </Button>
                        </span>
                    }
                    {   
                        myTimezone && showMyTimezone && myTimezone !== timezone && <span className="text-primary float-end">
                            Hours are displayed in {myTimezone} timezone. <Button color="link" onClick={() => setShowMyTimezone(false)}>
                                Show in default timezone ({timezone})
                            </Button>
                        </span>
                    }
                    <h4 className="text-primary bold" style={{fontWeight: '900', fontSize: '28px'}}>{ user.name } &gt; {active}</h4>
                </Col>
                {
                    needToCompleteData && <Col md="12"><Alert color="danger">You need to complete your 
                        {" "} profile to be able to arrange meetings</Alert></Col>
                }
                <Col md="3">
                    <Card style={{padding: '20px'}}>
                        <div className="text-center">
                            <img src={user.photo ? fileService.getUrl(JSON.parse(user.photo)[0].name) : "/profile2.png"} alt="profile" style={{ width: '50%' }} />
                        </div>
                        <br />
                        <Button color="outline-secondary" style={{width: '150px'}} onClick={selectFile} size="sm"><FontAwesomeIcon icon={faEdit} /> Change Picture</Button>
                        <Input type="file" style={{display: 'none'}} onChange={(e) => uploadProfilePicture(e.target.files[0])} ref={uploadFile} id="profilePicture" />
                        <br />
                        <h4>{user.name}</h4>
                        <p>{user.bio}</p>
                        <p>{getUserRole()}</p>
                        <br /> 
                        {
                            user.companyId !== 2 && <>
                                <NavLink to={`/company/${user.companyId}`} style={{textDecoration: 'none'}}><h3 className="text-warning">{user.company.title}</h3></NavLink>
                                
                                <h6>{user.company.city}, {user.company.country}</h6>
                                <br />
                            </>
                        }
                        
                        <br />
                        <Button color="primary" disabled={active==='Meetings'} onClick={() => setActive('Meetings')}>Meetings</Button>
                        <br />
                        <Button color="primary" disabled={active==='Profile'} onClick={() => setActive('Profile')}>Profile</Button>
                        <br />
                        <Button onClick={toggleChangePasswordModal}>Change Password</Button>

                    </Card>
                </Col>
                <Col md="9">
                    {
                        active === 'Meetings' && !needToCompleteData && <>
                            <Button size="lg" color="link" style={{fontWeight: 'bold', textDecoration: 'none'}} 
                                className={displaying === 'confirmed' ? "text-primary" : 'text-black'} onClick={() => setDisplaying('confirmed')}>Confirmed
                                ({myAppointments.filter(a => a.status === 'confirmed').length}) 
                            </Button>
                            <Button size="lg" color="link" style={{fontWeight: 'bold', textDecoration: 'none'}} 
                                className={displaying === 'pending' ? "text-primary" : 'text-black'} onClick={() => setDisplaying('pending')}>Pending
                                ({myAppointments.filter(a => a.status === 'pending').length}) </Button>
                            <Button size="lg" color="link" style={{fontWeight: 'bold', textDecoration: 'none'}} 
                                className={displaying === 'canceled' ? "text-primary" : 'text-black'} onClick={() => setDisplaying('canceled')}>Canceled
                                ({myAppointments.filter(a => a.status === 'canceled').length}) </Button>
                            <Button size="lg" color="link" style={{fontWeight: 'bold', textDecoration: 'none'}} 
                                className={displaying === 'completed' ? "text-primary" : 'text-black'} onClick={() => setDisplaying('completed')}>Completed
                                ({myAppointments.filter(a => a.status === 'completed').length}) </Button>
                            <Card style={{padding: '20px'}}>
                                {
                                    myAppointments.filter(a => a.status === displaying).map(a => {
                                        if(user.userId === a.userId){
                                            return <div key={a.appointmentId}>
                                                <Row>
                                                    <Col md="1">
                                                        <img src={a.userReceiver.photo ? fileService.getUrl(JSON.parse(a.userReceiver.photo)[0].name) : '/profile2.png'} width="100%" />
                                                    </Col>
                                                    <Col md="4">
                                                        <h3>{a.userReceiver.name}</h3>
                                                        <p>{a.userReceiver.roleInCompany}</p>
                                                        <h4 className="text-warning">{a.userReceiver.company.title}</h4>
                                                        <p>{a.userReceiver.company.city}, {a.userReceiver.company.country}</p>

                                                    </Col>
                                                    <Col md="4">
                                                        <span style={{borderBottom: '1px solid #000', marginBottom: '5px'}}> <FontAwesomeIcon icon={faClock} /> {
                                                            !showMyTimezone && a.datetime.split(' ')[3]
                                                        }
                                                        {
                                                            showMyTimezone && calcLocalTime(a.datetime.split(' ')[3])
                                                        }
                                                        </span><br />
                                                        <span style={{borderBottom: '1px solid #000', marginBottom: '5px'}}> <FontAwesomeIcon icon={faCalendar} /> &nbsp;
                                                            {a.datetime.split(' ')[0]} {a.datetime.split(' ')[1]} {a.datetime.split(' ')[2]}</span><br />
                                                        
                                                    </Col>
                                                    <Col md="3">
                                                        {
                                                            ['confirmed', 'pending'].includes(a.status) && 
                                                            <Button color="warning" size="sm" style={{width: '100%', marginTop: '10px'}} onClick={() => cancelMeeting(a.appointmentId)}>Cancel</Button>
                                                        }
                                                        {
                                                            ['confirmed'].includes(a.status) && 
                                                            <a className="btn btn-success btn-sm" style={{width: '100%', marginTop: '10px'}} target="_blank" href={a.callUrl} onClick={() => {
                                                                //setAppointmentUrl(a.callUrl);
                                                                startAppointnemt(a.appointmentId);
                                                                //toggleMeetingModal();
                                                            }}>Start Meeting</a>
                                                        }
                                                        {
                                                            ['confirmed'].includes(a.status) && 
                                                            <Button color="warning" size="sm" style={{width: '100%', marginTop: '10px'}} onClick={() => {
                                                                completeMeeting(a.appointmentId)
                                                            }}>Mark as Completed</Button>
                                                        }
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </div>;
                                        }
                                        else{
                                            return <div key={a.appointmentId}>
                                                <Row>
                                                    <Col md="1">
                                                        <img src={a.userRequested.photo ? fileService.getUrl(JSON.parse(a.userRequested.photo)[0].name) : '/profile2.png'} width="100%" />
                                                    </Col>
                                                    <Col md="4">
                                                        <h3>{a.userRequested.name}</h3>
                                                        <p>{a.userRequested.roleInCompany}</p>
                                                        <h4 className="text-warning">{a.userRequested.company.title}</h4>
                                                        <p>{a.userRequested.company.city}, {a.userRequested.company.country}</p>
                                                    </Col>
                                                    <Col md="4">
                                                        <span style={{borderBottom: '1px solid #000', marginBottom: '5px'}}> <FontAwesomeIcon icon={faClock} /> {
                                                            !showMyTimezone && a.datetime.split(' ')[3]
                                                        }
                                                        {
                                                            showMyTimezone && calcLocalTime(a.datetime.split(' ')[3])
                                                        }</span><br />
                                                        <span style={{borderBottom: '1px solid #000', marginBottom: '5px'}}> <FontAwesomeIcon icon={faCalendar} /> &nbsp;
                                                            {a.datetime.split(' ')[0]} {a.datetime.split(' ')[1]} {a.datetime.split(' ')[2]}</span><br />
                                                        <span style={{borderBottom: '1px solid #000', marginBottom: '5px'}}> <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
                                                            {a.message}</span>
                                                    </Col>
                                                    <Col md="3">
                                                        {
                                                            ['confirmed', 'pending'].includes(a.status) && 
                                                            <Button color="warning" size="sm" style={{width: '100%', marginTop: '10px'}} onClick={() => cancelMeeting(a.appointmentId)}>Cancel</Button>
                                                        }
                                                        {
                                                            ['confirmed', 'pending'].includes(a.status) && 
                                                            <Button color="primary" size="sm" style={{width: '100%', marginTop: '10px'}} onClick={() => rescheduleMeeting(a.appointmentId, a.userId)}>Reschedule</Button>
                                                        }
                                                        {
                                                            ['confirmed'].includes(a.status) && 
                                                            <a className="btn btn-success btn-sm" style={{width: '100%', marginTop: '10px'}} target="_blank" href={a.callUrl} onClick={() => {
                                                                //setAppointmentUrl(a.callUrl);
                                                                startAppointnemt(a.appointmentId)
                                                                //toggleMeetingModal();
                                                            }}>Start Meeting</a>
                                                        }
                                                        {
                                                            ['pending'].includes(a.status) && 
                                                            <Button color="success" onClick={() => confirmMeeting(a.appointmentId)} size="sm" style={{width: '100%', marginTop: '10px'}}>Confirm</Button>
                                                        }
                                                        {
                                                            ['confirmed'].includes(a.status) && 
                                                            <Button color="warning" size="sm" style={{width: '100%', marginTop: '10px'}} onClick={() => {
                                                                completeMeeting(a.appointmentId)
                                                            }}>Mark as Completed</Button>
                                                        }
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </div>;
                                        }
                                    })
                                }
                            </Card>
                        </>
                    }
                    {
                        active === 'Profile' && <Card>
                            {
                                successMessage && <Alert color="success">{successMessage}</Alert>
                            }
                            <Form onSubmit={updateProfile}>
                                <FormGroup>
                                    <Label>Role in Company</Label>
                                    <Input type="text" rows="4" defaultValue={user.bio} onChange={(e) => {
                                            setUser({
                                                ...user,
                                                bio: e.target.value
                                            });
                                            localStorage.setItem('digitalEventUser', JSON.stringify({...user, bio: e.target.value}))
                                        }
                                    }/>
                                </FormGroup>
                                {
                                    event.dataToComplete && JSON.parse(event.dataToComplete).map((dt, i) => {
                                        if(dt.type === 'Text'){
                                            return <FormGroup key={i}>
                                                <Label>{dt.name}</Label>
                                                <Input type='textarea' rows="3" defaultValue={user.data ? JSON.parse(user.data)[dt.name] : ''} 
                                                    onChange={(e) => updateUserData(dt.name, e.target.value)} />
                                                <hr />
                                            </FormGroup>
                                        }
                                        if(dt.type === 'Date'){
                                            return <FormGroup key={i}>
                                                <Label>{dt.name}</Label>
                                                <Input type='date' defaultValue={user.data ? JSON.parse(user.data)[dt.name] : ''} 
                                                    onChange={(e) => updateUserData(dt.name, e.target.value)} />
                                                <hr />
                                            </FormGroup>
                                        }
                                        if(dt.type === 'Dropdown'){
                                            return <FormGroup key={i}>
                                                <Label>{dt.name}</Label>
                                                <Input type='select' defaultValue={user.data ? JSON.parse(user.data)[dt.name] : ''} 
                                                    onChange={(e) => updateUserData(dt.name, e.target.value)} >
                                                    <option value="">Select one of the below</option>
                                                    {
                                                        dt.options.split('|').map(op => <option key={op}>{op}</option>)
                                                    }
                                                </Input>
                                                <hr />
                                            </FormGroup>
                                        }
                                        if(dt.type === 'Role'){
                                            return <FormGroup key={i}>
                                                <Label>{dt.name}</Label>
                                                <Input type='select' defaultValue={user.data ? JSON.parse(user.data)[dt.name] : ''} 
                                                    onChange={(e) => updateUserData(dt.name, e.target.value)} >
                                                    <option value="">Select one of the below</option>
                                                    {
                                                        dt.options.split('|').map(op => <option key={op}>{op}</option>)
                                                    }
                                                </Input>
                                                <hr />
                                            </FormGroup>
                                        }
                                        if(dt.type === 'Timezone'){
                                            return <FormGroup key={i}>
                                                <Label>{dt.name}</Label>
                                                <Input type='select' defaultValue={user.data ? JSON.parse(user.data)[dt.name] : ''} 
                                                    onChange={(e) => updateUserData(dt.name, e.target.value)} >
                                                    <option value="">Select one of the below</option>
                                                    {
                                                        timezones.map(t => <option value={t.tzCode} key={t.label}>{t.label}</option>)
                                                    }
                                                </Input>
                                                <hr />
                                            </FormGroup>
                                        }
                                    })
                                }
                                <br />
                                <Button color="primary">Save</Button>
                            </Form>
                        </Card>
                    }
                    
                </Col>
            </>
            }
        </Row>
        <Modal isOpen={showChangePasswordModal} toggle={toggleChangePasswordModal} className="modal-lg">
            <ModalHeader toggle={toggleChangePasswordModal}>Video call</ModalHeader>
            <ModalBody>
                <Form onSubmit={changePassword}>
                    {
                        errorPassMessage && <Alert color="danger">{ errorPassMessage }</Alert>
                    }
                    
                    <FormGroup>
                        <Label>New Password</Label>
                        <Input type="password" onChange={(e) => setNewPassword(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Repeat New Password</Label>
                        <Input type="password" onChange={(e) => setRepeatPassword(e.target.value)} />
                    </FormGroup>
                    <Button color="warning" >Change Password</Button>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={toggleChangePasswordModal}>Close</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={showMeetingModal} toggle={toggleMeetingModal} className="modal-xl">
            <ModalHeader toggle={toggleMeetingModal}>Video call</ModalHeader>
            <ModalBody>
                {
                    appointmentUrl && <iframe allow="camera; microphone; fullscreen; speaker; display-capture" style={{width: '100%', height: '70vh'}} src={appointmentUrl}></iframe>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={toggleMeetingModal}>Close</Button>
            </ModalFooter>
        </Modal>
    </>
};

export default Profile;

